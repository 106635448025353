@tailwind base;
@tailwind components;
@tailwind utilities;

/* @media screen and (pointer: fine) {
  html {
    margin-right: calc(-1 * (100vw - 100%));
  }

  ::-webkit-scrollbar {
    height: 0.75rem;
    width: 0.75rem;
    background: #fafafa;
  }

  ::-webkit-scrollbar-thumb {
    background: #37415188;
    border-block: 0.4rem solid white;
    border-inline: 0.2rem solid white;
    border-radius: 1rem;
  }

  ::-webkit-scrollbar-track {
    background: #fafafa;
  }
} */

html {
  overflow-x: hidden;
  overflow-y: scroll;
  min-height: 100dvh;
}

:root {
  --scroll-offset: 0px; /* Default for desktop */
}

@media (max-width: 640px) {
  :root {
    --scroll-offset: 72px; /* Offset for mobile */
  }
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fafafa;
}

main {
  margin: auto;
  padding-bottom: 6rem;
}

.modal {
  margin: 0;
  padding: 2rem;
  position: fixed;
  top: 10vh;
  left: calc(50% - 15rem);
  width: 30rem;
  max-height: 80vh;
  background: rgba(255, 255, 255, 0.9);
  border: none;
  border-radius: 1rem;
  z-index: 100;
  box-shadow: 0 40px 50px -10px rgb(0 0 0 / 0.1), 0 16px 20px -12px rgb(0 0 0 / 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  animation: slide-down-fade-in 180ms ease-out;
}

@keyframes slide-down-fade-in {
  from {
    opacity: 0;
    scale: 0.9;
  }

  to {
    opacity: 1;
    scale: 1;
  }
}

.modal::backdrop {
  height: 100vh;
  width: 100%;
  backdrop-filter: blur(5px);
  background: rgba(0, 0, 0, 0.54);
  position: fixed;
  top: 0;
  left: 0;
  -webkit-opacity: 0;
  -webkit-animation: fade-in 180ms ease-out forwards;
}

@keyframes fade-in {
  to {
    opacity: 1;
  }
}

@media only screen and (max-width: 767px) {
  .modal {
    left: calc(50% - 12rem);
    width: 24rem;
  }
}

img {
  object-fit: cover !important;
  width: 100%;
  height: 100%;
}

details > summary {
  list-style: none;
}

details > summary::marker, /* Latest Chrome, Edge, Firefox */ 
details > summary::-webkit-details-marker /* Safari */ {
  display: none;
}

[disabled] {
  opacity: 0.4;
}

@media only screen and (max-width: 1239px) {
  html {
    font-size: 14px;
  }
}

@media only screen and (min-width: 1440px) {
  html {
    font-size: 16px;
    /* font-size: 15px; */ /* ==================================== BEFORE=============================== */
  }
}

.bg-skeleton {
  background: linear-gradient(-150deg, hsl(215, 26%, 88%), #fff, hsl(215, 26%, 88%));
  background-size: 100vw 100%;
  /*animation: skeleton-animation 2s ease infinite;*/
}

@keyframes skeleton-animation {
  0% {
    background-position: 20% 0%;
  }
  35% {
    background-position: 80% 0%;
  }
  40% {
    background-position: 80% 0%;
  }
  89% {
    background-position: 20% 0%;
  }
  100% {
    background-position: 20% 0%;
  }
}

/* On mobile has to be a bigger number */
@media only screen and (max-width: 767px) {
  .bg-skeleton {
    background-size: 700px 700px;
  }

  @keyframes skeleton-animation {
    0% {
      background-position: 0% 50%;
    }
    35% {
      background-position: 100% 50%;
    }
    40% {
      background-position: 100% 50%;
    }
    89% {
      background-position: 0% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
}

/* Print */
@media print {
  /* Prevent page numbers, URLs in print */
  @page {
    size: auto;
    margin: 0; /* Remove default margins */
  }

  html {
    width: 100%;
    margin: auto;
    font-size: 7.75pt;
  }

  body {
    background: white!important;
  }

  #header {
    display: none;
  }

  main {
    padding: 4rem 2rem 2rem!important;
  }
}